import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TimePickerStatus } from '../../ViewModel/timePickerViewModel/timePickerViewModel';
import {
  CalendarErrors,
  TimeSlotsAvailability,
  TriggeredByOptions,
} from '../../../../utils/bi/consts';
import { QueryAvailabilityResponse } from '@wix/ambassador-availability-calendar/types';
import {
  getTimeSlotsAvailabilityStatuses,
  TimeSlotAvailabilityStatus,
} from '../../../../utils/timeSlots/timeSlots';
import { Optional } from '../../../../types/types';
import { AddError } from '../addError/addError';
import { RemoveError } from '../removeError/removeError';

export type SetSelectedDate = (
  localDateTime: string,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createSetSelectedDateAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  addError: AddError,
  removeError: RemoveError,
): SetSelectedDate {
  return async (localDateTime: string, triggeredBy: TriggeredByOptions) => {
    removeErrorsIfExist(removeError);
    const [state, setState] = getControllerState();
    const { calendarApi, settings } = context;
    const selectedDate = localDateTime;
    setState({
      selectedDate,
      timePickerStatus: TimePickerStatus.LOADING,
      selectedDateTrigger: triggeredBy,
    });

    const availableSlots = await calendarApi.getSlotsForSelectedDate(
      selectedDate,
      { state, settings, onError: addError },
    );

    if (selectedDate === getControllerState()[0].selectedDate) {
      setState({ availableSlots });

      if (availableSlots?.availabilityEntries?.length) {
        setState({ timePickerStatus: TimePickerStatus.LOADED });
      } else {
        setState({
          timePickerStatus:
            TimePickerStatus.NO_AVAILABLE_SLOTS_FOR_SELECTED_DATE,
        });
      }
      sendTimePickerLoadedBiEvent(
        availableSlots,
        triggeredBy,
        selectedDate,
        context,
      );
    }
  };
}

const sendTimePickerLoadedBiEvent = (
  availableSlots: Optional<QueryAvailabilityResponse>,
  triggeredBy: TriggeredByOptions,
  selectedDate: string,
  context: CalendarContext,
) => {
  const { biLogger } = context;

  const timeSlotsAvailabilityStatuses: Map<
    string,
    TimeSlotAvailabilityStatus
  > = getTimeSlotsAvailabilityStatuses(availableSlots);

  const timeSlotsAvailability = getTimeSlotsAvailabilityForBi(
    timeSlotsAvailabilityStatuses,
  );
  void biLogger.bookingsCalendarTimePickerLoad({
    triggeredBy,
    selectedDate,
    timeSlotsAvailability: JSON.stringify(timeSlotsAvailability),
  });
};

const getTimeSlotsAvailabilityForBi = (
  timeSlotsByAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>,
): TimeSlotsAvailability => {
  const timeSlotsAvailability: TimeSlotsAvailability = {
    AVAILABLE: 0,
    FULL: 0,
    TOO_LATE_TO_BOOK: 0,
    TOO_EARLY_TO_BOOK: 0,
    WAITLIST: 0,
  };

  timeSlotsByAvailabilityStatuses.forEach(
    (status: TimeSlotAvailabilityStatus) => {
      if (status.tooLateToBookAllSlots) {
        timeSlotsAvailability.TOO_LATE_TO_BOOK++;
      } else if (status.allSlotsAreFull && !status.withWaitingList) {
        timeSlotsAvailability.FULL++;
      } else if (status.withWaitingList) {
        timeSlotsAvailability.WAITLIST++;
      } else if (status.tooEarlyToBookAllSlots) {
        timeSlotsAvailability.TOO_EARLY_TO_BOOK++;
      } else {
        timeSlotsAvailability.AVAILABLE++;
      }
    },
  );

  return timeSlotsAvailability;
};

const removeErrorsIfExist = (removeError: RemoveError) => {
  removeError(CalendarErrors.NEXT_AVAILABLE_DATE_SERVER_ERROR);
  removeError(CalendarErrors.NO_NEXT_AVAILABLE_DATE);
  removeError(CalendarErrors.AVAILABLE_SLOTS_SERVER_ERROR);
};
